import React from "react";


export const Footer = (props) => {
  return (
    <div>
      <div id="footer">
        <div className="container text-center">
          <p>
            &copy; 2024 Chámar Servicios Auxiliares. Todos los derechos reservados
          </p>
        </div>
      </div>
    </div>
  );
};
